/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";

function SectionWidget({ titleText }) {
  return (
    <>
      <div className="page-head">
        <h4 className="heading">{titleText}</h4>
        <Breadcrumb
          itemColor={"rgba(0, 0, 0, 0.88)"}
          items={[
            {
              href: "/",
              title: <HomeOutlined />,
            },
            {
              title: <p>{titleText}</p>,
            },
          ]}
        />
      </div>
    </>
  );
}

export default SectionWidget;
