/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
function NotFoundSection({ title }) {
  return (
    <>
      <div>
        <center>
          <img
            style={{ minWidth: "40%", maxWidth: "100%" }}
            src="./images/conditions/sorry-item-not-found.png"
            alt=""
          />
        </center>
        {title !== "" ? (
          <h4 style={{ textAlign: "center" }} className="career-title">
            {title}
          </h4>
        ) : (
          <h4 style={{ textAlign: "center" }} className="career-title">
            Not Found
          </h4>
        )}
      </div>
    </>
  );
}

export default NotFoundSection;
