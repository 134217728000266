/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function OurFeatureSection({ showTitle }) {
  return (
    <>
      <div className="whiteCard-Center defult-padding" wrap="true">
        {showTitle === true ? (
          <center>
            <h4 className="heading">
              OUR BEST <strong className="company-name">FEATURES</strong>
            </h4>
            <div className="divider"></div>
          </center>
        ) : (
          <div></div>
        )}

        <Container>
          <Row>
            <Col>
              <div className="item about-card card-animation">
                <img
                  className="about-card-image"
                  src="./images/about/android.svg"
                  alt=""
                />
                <h3 className="about-card-heading">ANDROID APPLICATIONS</h3>

                <blockquote>
                  <p className="about-card-paragraph">
                    Android Applications are dominating the mobile app markets.
                    If you want to scale your business to the next level.
                    Connect AW Digital Support for affordable Android Apps.
                  </p>
                </blockquote>
              </div>
            </Col>
            <Col>
              <div className="item about-card card-animation">
                <img
                  className="about-card-image"
                  src="./images/about/video-tutorials.svg"
                  alt=""
                />
                <h3 className="about-card-heading">VIDEO PROMOTION</h3>

                <blockquote>
                  <p className="about-card-paragraph">
                    Creative team of AW designs engaging videos to serve your
                    target audience. Video is easily accessible & makes a better
                    impression of your products/services.
                  </p>
                </blockquote>
              </div>
            </Col>

            <Col>
              <div className="item about-card card-animation">
                <img
                  className="about-card-image"
                  src="./images/about/promotion.svg"
                  alt=""
                />
                <h3 className="about-card-heading">BRAND PROMOTION</h3>

                <blockquote>
                  <p className="about-card-paragraph">
                    Planning Strategies to promote your business and creating a
                    brand in your niche is our motto. Brand promotion tends to
                    build loyalty and a long-term customer base.
                  </p>
                </blockquote>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="item about-card card-animation">
                <img
                  className="about-card-image"
                  src="./images/about/website.svg"
                  alt=""
                />
                <h3 className="about-card-heading">WEB DEVELOPMENT</h3>

                <blockquote>
                  <p className="about-card-paragraph">
                    If you run a business and planning to expand it out of your
                    local network then you must need a website to reach a wide
                    audience.
                  </p>
                </blockquote>
              </div>
            </Col>
            <Col>
              <div className="item about-card card-animation">
                <img
                  className="about-card-image"
                  src="./images/about/design-tools.svg"
                  alt=""
                />
                <h3 className="about-card-heading">GRAPHIC DESIGNING</h3>

                <blockquote>
                  <p className="about-card-paragraph">
                    Designers of AW Digital Support show visuals’ power by
                    creating extraordinary designs that represent your business
                    to the world.
                  </p>
                </blockquote>
              </div>
            </Col>

            <Col>
              <div className="item about-card card-animation">
                <img
                  className="about-card-image"
                  src="./images/about/promotion.svg"
                  alt=""
                />
                <h3 className="about-card-heading">SOCIAL MEDIA MARKETING</h3>

                <blockquote>
                  <p className="about-card-paragraph">
                    Connecting with the potential audience to boost brand trust
                    using effective social media platforms that ultimately
                    maximize your success.
                  </p>
                </blockquote>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default OurFeatureSection;
