import React from "react";
import { Layout } from "antd";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import FooterWidget from "./screens/inc/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Import Widgets
import HeaderWidget from "./screens/inc/Header";
import HomeScreen from "./screens/home/Home";
import AboutScreen from "./screens/about-us/About";
import ProductDevelopment from "./screens/product-development/ProductDevelopment";
import GraphicDesign from "./screens/graphic-design/GraphicDesign";
import DigitalMarketing from "./screens/digital-marketing/DigitalMarketing";
import PortfolioScreen from "./screens/portfolio/Portfolio";
import PortfolioDetail from "./screens/portfolio/PortfolioDetail";
import ContactUs from "./screens/contact-us/ContactUs";
import Career from "./screens/career/Career";
import BlogsScreen from "./screens/blogs/Blogs";
import BlogsDetail from "./screens/blogs/BlogsDetail";
import ScrollToTop from "./screens/inc/ScrollTop";
const App = () => {
  return (
    <Router>
      <Layout>
        <HeaderWidget />
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<HomeScreen />} />
          <Route exact path="/about" element={<AboutScreen />} />
          <Route
            exact
            path="/product-development"
            element={<ProductDevelopment />}
          />
          <Route
            exact
            path="/digital-marketing"
            element={<DigitalMarketing />}
          />
          <Route exact path="/graphic-design" element={<GraphicDesign />} />
          <Route exact path="/aw-portfolio" element={<PortfolioScreen />} />
          <Route path="/aw-portfolio-detail" element={<PortfolioDetail />} />
          <Route path="/aw-career" element={<Career />} />
          <Route exact path="/blogs" element={<BlogsScreen />} />
          <Route path="/blog-detail" element={<BlogsDetail />} />
          <Route path="/aw-contact-us" element={<ContactUs />} />
        </Routes>
        <FooterWidget />
      </Layout>
    </Router>
  );
};
export default App;
