/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Form, Input, Col, Row } from "antd";
import { CALL_NUM, MAIL, WHATSAPP_NUM } from "../../utils/AppConstant";
import { getAPI, postAPI } from "../../utils/common";
import { ALL_DYNAMIC_CONTENT, CREATE_CONTACTS } from "../../utils/AppConstant";
import { displayMessage } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../utils/messages";
function FooterWidget() {
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);

  useLayoutEffect(() => {
    if (state.length === 0) {
      getData();
    }
  }, [state, loading]);
  const getData = () => {
    const successFn = function (data) {
      console.log(data);
      // Take the first 4 items from the received data
      const firstFourItems = data["data"].slice(0, 4);
      setloading(false);
      // Set the state with the first 4 items
      setstate(firstFourItems);
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(ALL_DYNAMIC_CONTENT + "0", successFn, errorFn, {});
  };

  const onFinish = (values) => {
    const { email } = values.user;
    const data = new FormData();

    data.append("name", "Unknow");
    data.append("email", email);
    data.append("phone", "Unknow");
    data.append("whatsapp_number", "Unknow");
    data.append("subject", "Subscribe");
    data.append("interested_in", "Subscribe");
    data.append("message", "Subscribe");
    const successFn = function (data) {
      console.log(data);
      if (data["status"] === true) {
        displayMessage(SUCCESS_MESSAGE, "Request has been submited");
      } else {
        displayMessage(ERROR_MSG_TYPE, data["message"]);
      }
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to send the request");
    };
    postAPI(CREATE_CONTACTS, data, successFn, errorFn);
  };

  return (
    <>
      <div
        className="data-container teamCard defult-padding-left defult-padding-right"
        wrap="true"
      >
        <div className="data-item ">
          <div className="image3d"></div>
          <h1 className="team-heading-h1">CONTACT US</h1>
          <h4 className="team-heading">
            HAVE A PROJECT IN MIND? LET'S GET TO CHAT.
          </h4>

          <a href={"tel:" + CALL_NUM} className="team-call-btn">
            <i className="fa fa-phone  text-white"></i> Call Now
          </a>
          <spacer />
          <a
            href={"https://wa.me/" + WHATSAPP_NUM}
            target="_blanck"
            className="team-whatsapp-btn"
          >
            <i className="fa fa-whatsapp  text-white"></i> Whatsapp
          </a>
        </div>
        <div className="data-item team-image">
          <img
            className="team-image"
            src="./images/footer/team-support.jpg"
            alt="Team-Support"
          />
        </div>
      </div>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-cta pt-5 pb-5">
            <div className="row">
              <div className="col-xl-3 col-md-3 padding-top30">
                <div className="single-cta">
                  <div style={{ float: "left" }} className="image3d"></div>
                  <div className="cta-text">
                    <h4>Reach us</h4>
                    <span>AW Digital Support</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-3 padding-top30">
                <a href={"https://wa.me/" + WHATSAPP_NUM}>
                  <div className="single-cta">
                    <i className="fa fa-whatsapp"></i>
                    <div className="cta-text">
                      <h4>Chat us</h4>
                      <span>{WHATSAPP_NUM}</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-md-3 padding-top30">
                <a href={"tel:" + CALL_NUM}>
                  <div className="single-cta">
                    <i className="fas fa-phone"></i>
                    <div className="cta-text">
                      <h4>Call us</h4>
                      <span>{CALL_NUM}</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-md-3 padding-top30">
                <a href={"mailto:" + MAIL}>
                  <div className="single-cta">
                    <i className="far fa-envelope-open"></i>
                    <div className="cta-text">
                      <h4>Mail us</h4>
                      <span> {MAIL}</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="footer-content pt-5 pb-5">
            <div className="row" style={{ justifyContent: "space-between" }}>
              <div className="col-xl-3 col-lg-3 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a href="/">
                      <img
                        src="./images/aw_logo_white.png"
                        className="img-fluid"
                        alt="AW-LOGO"
                      />
                    </a>
                  </div>
                  <div className="footer-text">
                    <p>
                      AW Digital Support is founded by a group of youth
                      enthusiasts to change the working...
                    </p>
                  </div>
                  <div className="footer-social-icon ">
                    <span>Follow us</span>
                    <a href="https://facebook.com/awdigitalsupport">
                      <i className="fab fa-facebook-f facebook-bg"></i>
                    </a>
                    <a href="https://twitter.com/awdigitalsuppor">
                      <i className="fab fa-twitter twitter-bg"></i>
                    </a>
                    <a href="https://instagram.com/awdigitalsupport">
                      <i className="fab fa-instagram instargam-bg"></i>
                    </a>
                    <a href="https://instagram.com/awdigitalsupport">
                      <i className="fab fa-linkedin linkedin-bg"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Letest Blog</h3>
                  </div>
                  {state.length > 0 ? (
                    state.map((item, itemIndex) => (
                      <li className="useful-links-li">
                        <NavLink to={`/blog-detail?slug=${item.slug}`}>
                          {item.meta_title}
                        </NavLink>
                      </li>
                    ))
                  ) : (
                    <li className="useful-links-li"></li>
                  )}
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <li className="useful-links-li">
                    <NavLink exact to="/about">
                      About-Us
                    </NavLink>
                  </li>
                  <li className="useful-links-li">
                    <NavLink exact to="/blogs">
                      Blogs
                    </NavLink>
                  </li>
                  <li className="useful-links-li">
                    <NavLink exact to="/aw-career">
                      career
                    </NavLink>
                  </li>
                  <li className="useful-links-li">
                    <NavLink exact to="/aw-portfolio">
                      portfolio
                    </NavLink>
                  </li>
                  <li className="useful-links-li">
                    <NavLink exact to="/aw-contact-us">
                      Contact
                    </NavLink>
                  </li>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Subscribe</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                      Don’t miss to subscribe to our new feeds, kindly fill the
                      form below.
                    </p>
                  </div>
                  <div className="subscribe-form">
                    <Form name="nest-messages" onFinish={onFinish}>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Row
                          style={{ margin: 0, padding: 0 }}
                          justify="space-between"
                        >
                          <Col span={18}>
                            <Form.Item
                              name={["user", "email"]}
                              // label="Email Address"
                              rules={[
                                {
                                  type: "email",
                                },
                              ]}
                            >
                              <Input placeholder="Email Address" />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item>
                              <Button
                                style={{ height: "auto" }}
                                type="primary"
                                htmlType="submit"
                              >
                                <i className="fab fa-telegram-plane"></i>
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Form>
                    {/* <form action="#">
                      <input type="text" placeholder="Email Address" />
                      <button></button>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container pt-15 pb-10">
            <div className="row">
              <div className="col-md-6">
                <p style={{ color: "#878787" }}>
                  Copyright ©2019 AW Digital Support. All Rights Reserved
                </p>
              </div>
              {/* <div className="col-md-6 text-right">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <NavLink exact to="/aw-contact-us">
                        Help Desk
                      </NavLink>
                    </li>
                    <li>|</li>
                    <li>
                      <NavLink exact to="/aw-contact-us">
                        Support
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default FooterWidget;
