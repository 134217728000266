/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function ProductDevelopmentSection() {
  return (
    <>
      <div className="data-container whiteCard" wrap="true">
        <div className="data-item defult-padding">
          <div className="image3d"></div>
          <h4 className="heading">Product Development</h4>
          <div className="divider"></div>
          <p className="paragarph">
            We work on your ideas, check your requirements, and find the
            solution to your problem. The tech wizards of our team provide the
            most innovative solution to all your problems.
          </p>
          <p className="paragarph">
            <ul>
              <li>Web Applications</li>
              <li>AI Solutions</li>
              <li>IOT Solutions</li>
              <li>E-Commerce</li>
              <li>Mobile Applications</li>
            </ul>
          </p>
        </div>
        <div className="data-item midle-image">
          <center>
            <img
              className="midle-image"
              src="./images/home/product-development.png"
              alt="Product-Development"
            />
          </center>
        </div>
      </div>
    </>
  );
}

export default ProductDevelopmentSection;
