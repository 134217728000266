/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function DigitalMarketingSection() {
  return (
    <>
      <div className="data-container whiteCard" wrap="true">
        <div className="data-item midle-image">
          <center>
            <img
              className="midle-image"
              src="./images/home/digital-marketing.png"
              alt="Digital-Marketing"
            />
          </center>
        </div>
        <div className="data-item defult-padding">
          <div className="image3d"></div>
          <h4 className="heading">Digital Marketing</h4>
          <div className="divider"></div>
          <p className="paragarph">
            The words of our writers are mightier than weapons. They not only
            create the scripts and prepare the contents but also plan the
            campaigns and make the brands trend online. From socializing to
            advertising, they do it all in their own buzzing style.
          </p>
          <p className="paragarph">
            <ul>
              <li>Social Media Marketing</li>
              <li>Content Marketing</li>
              <li>Search Engine Optimization</li>
              <li>Copy Writing</li>
              <li>Mobile Applications</li>
            </ul>
          </p>
        </div>
      </div>
    </>
  );
}

export default DigitalMarketingSection;
