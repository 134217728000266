/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from "react";
import SectionWidget from "../widgets/SectionWidget";
import { useLocation } from "react-router-dom";
import { postAPI } from "../../utils/common";
import { Helmet } from "react-helmet";
import {
  DYNAMIC_CONTENT_BY_SLUG,
  BLOG_BASE_URL,
} from "../../utils/AppConstant";
import { displayMessage } from "../../utils/common";
import { ERROR_MSG_TYPE } from "../../utils/messages";
import Carousel from "react-bootstrap/Carousel";

function PortfolioDetail() {
  // Use the useLocation hook to get the location object
  const location = useLocation();

  // Use URLSearchParams to access the query parameter 'title'
  const searchParams = new URLSearchParams(location.search);
  const title = searchParams.get("slug");

  const [state, setstate] = useState(null);
  const [loading, setloading] = useState(true);

  useLayoutEffect(() => {
    if (state === null) {
      getData();
    }
  }, [state, loading]);
  const getData = () => {
    const data = new FormData();
    data.append("slug", title);

    const successFn = function (data) {
      console.log(data);
      setloading(false);
      setstate(data["data"]);
      console.log(state);
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    postAPI(DYNAMIC_CONTENT_BY_SLUG, data, successFn, errorFn);
  };

  return (
    <>
      {state !== null ? <SectionWidget titleText={state.title} /> : <div></div>}
      {state !== null ? (
        <div className="whiteCard defult-padding">
          <Helmet>
            <title>{state.meta_title}</title>
            <meta name="description" content={state.meta_description} />
            <meta name="keywords" content={state.meta_keyword} />
            {/* Dynamic OG Tags for Social Sharing */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`${window.location.href}`} />
            <meta property="og:title" content={state.meta_title} />
            <meta property="og:description" content={state.meta_description} />
            <meta
              property="og:image"
              content={BLOG_BASE_URL + state.images[0].image}
            />
            <meta
              name="og:keywords"
              property="og:keywords"
              content={state.meta_keyword}
            />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={`${window.location.href}`} />
            <meta property="twitter:title" content={state.meta_title} />
            <meta
              property="twitter:description"
              content={state.meta_description}
            />
            <meta
              property="twitter:image"
              content={BLOG_BASE_URL + state.images[0].image}
            />
          </Helmet>
          <center>
            <Carousel loading={loading} className="home-slider">
              {state.images.map((item, itemIndex) => (
                <Carousel.Item interval={1000}>
                  <img
                    src={BLOG_BASE_URL + item.image}
                    alt={item.title}
                    className="dynamic-content-slider"
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </center>
          <br />
          {/* <h4
            className="heading"
            style={{ textAlign: "start", color: "black" }}
          >
            <strong
              style={{ textAlign: "start", color: "black" }}
              className="company-name"
            >
              {state.title}
            </strong>
          </h4> */}
          <div className="divider"></div>
          <br />
          <p dangerouslySetInnerHTML={{ __html: state.description }} />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default PortfolioDetail;
