/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function GraphicDesignSection() {
  return (
    <>
      <div className="data-container whiteCard" wrap="true">
        <div className="data-item defult-padding">
          <div className="image3d"></div>
          <h4 className="heading">Graphic Design</h4>
          <div className="divider"></div>
          <p className="paragarph">
            We work on Creative concepts that give a look and feel to your
            brand, check your requirements, and make a premium design for your
            brand and look. The Designers of our team provide the most Creative
            & unique designs for your brand.
          </p>
          <p className="paragarph">
            <ul>
              <li>Social Media post</li>
              <li>Logo Design</li>
              <li>UI Design</li>
              <li>Flyer Design</li>
              <li>Brand Design</li>
            </ul>
          </p>
        </div>
        <div className="data-item midle-image">
          <center>
            <img
              className="midle-image"
              src="./images/home/graphics.png"
              alt="Graphics"
            />
          </center>
        </div>
      </div>
    </>
  );
}

export default GraphicDesignSection;
