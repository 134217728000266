/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function OurMoscotSection() {
  return (
    <>
      <div className="data-container blackCard" wrap="true">
        <div className="data-item engaging-image">
          <video
            src="./images/gif.mp4"
            muted
            autoPlay={"autoplay"}
            preLoad="auto"
            loop
            className="engaging-image image-animation-left-in"
          ></video>
          {/* <img
            className="engaging-image"
            src="./images/home/rocket-image.png"
            alt="Rocket"
          /> */}
        </div>
        <div className="data-item small-padding">
          <div className="image3d"></div>
          <h4 className="white-heading">Our Mascot</h4>
          <div className="white-divider"></div>
          <p className="white-paragarph">
            Well, obviously we believe pluto is a planet! Regardless of its
            size, it represents the strength and the solidified notion that
            one’s character must possess. That’s what our Mascot, Pluto implies.
            Despite being terribly cute, it signifies the solidified virtue of
            our work quality. We believe no matter how small a task may seem, it
            is never insignificant and always requires effort for perfection.
            Living by this Motto, we are building our own universe with pluto as
            its center. Pluto is like the carrier of our creativity and can be
            seen on every page. We absolutely adore it and hope you love it too!
          </p>
        </div>
      </div>
    </>
  );
}

export default OurMoscotSection;
