/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SectionWidget from "../widgets/SectionWidget";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function GraphicDesign() {
  return (
    <>
      <SectionWidget titleText={"Graphic Design"} />
      <div className="data-container whiteCard defult-padding" wrap="true">
        <Helmet>
          <title>GRAPHIC DESIGNING</title>
          <meta
            name="description"
            content="AW Digital Support offers top-notch graphic design services, transforming your ideas into captivating visual content. Our skilled designers create stunning logos, brochures, banners, and more, tailored to your unique brand identity. We blend creativity with precision to deliver compelling visuals that leave a lasting impression. Elevate your marketing materials and online presence with our professional graphic design solutions."
          />
          <meta
            name="keywords"
            content="Graphic design, logo design, branding, creative visuals, professional design services, marketing materials, online presence, banners, brochures, AW Digital Support, AW Digital Support, AW, Digital, Digital Support"
          />
          {/* Dynamic OG Tags for Social Sharing */}
          <meta property="og:title" content="GRAPHIC DESIGNING" />
          <meta
            property="og:description"
            content="AW Digital Support offers top-notch graphic design services, transforming your ideas into captivating visual content. Our skilled designers create stunning logos, brochures, banners, and more, tailored to your unique brand identity. We blend creativity with precision to deliver compelling visuals that leave a lasting impression. Elevate your marketing materials and online presence with our professional graphic design solutions."
          />
          <meta
            property="og:keywords"
            content="Graphic design, logo design, branding, creative visuals, professional design services, marketing materials, online presence, banners, brochures, AW Digital Support, AW Digital Support, AW, Digital, Digital Support"
          />
        </Helmet>
        <div className="data-item engaging-image">
          <img
            className="engaging-image image-animation-left-in"
            src="./images/multimedia/multimedia.png"
            alt=""
          />
        </div>
        <div className="data-item ">
          <div className="image3d"></div>
          <h4 className="company-heading heading-animation">
            <strong className="company-name">PURSUIT OF PERFECTION</strong>
          </h4>
          <div className="divider"></div>
          <p className="paragarph">
            We thrive for the paramount and agonize to actualize it. We work
            behind the curtain, so that spotlight always accentuates on you. We
            define the path, from a Business to the Brand by accumulating all
            the essential assets and utilizing them to their best potential for
            your betterment. Our sole pursuit is to sculpt your brand with the
            utmost supremacy and carve its way to triumph. Success is our
            solitary intent and we never settle for even a notch under that. In
            pursuit of perfection, our designers keep learning new technologies
            and setting benchmarks of excellence.
          </p>
          <p className="paragarph">We are designing a new world.</p>
        </div>
      </div>
      <div className="data-container greyCard defult-padding" wrap="true">
        <div className="">
          <div className="image3d"></div>
          <h3 className="team-heading heading-animation">
            <strong className="company-name">MULTIMEDIA</strong>
          </h3>
          <div className="divider"></div>
          <p className="paragarph">
            With the most creative-minded people awarded with skills and talent,
            our team creates the best quality products. May it be high-quality
            videos, animated logos, infographics, or the website UI, we sculpt
            each masterpiece with wisdom and efforts. Multimedia requires a
            blend of creativity and technology. Getting hands-on both together
            is a skill we’ve mastered.
          </p>
          <p className="paragarph">
            We offer various services, video editing, e-book designing,
            illustrations, brochure designing, UI/UX Designing, Graphics
            designing, multimedia animations, and many more.
          </p>
          <p className="paragarph">
            We do everything except editing your photographs, well, we know how
            to do it, and might actually do that too, submit your query and
            we’ll get back to you!
          </p>
        </div>
      </div>
      <div className="whiteCard-Center defult-padding" wrap="true">
        <Container>
          <Row>
            <Col>
              <div className="item about-card card-animation">
                <img
                  className="about-card-image"
                  src="./images/about/android.svg"
                  alt=""
                />
                <h3 className="about-card-heading">UI/UX DESIGN</h3>

                <blockquote>
                  <p className="about-card-paragraph">
                    Multimedia animation is a huge domain in itself. There is
                    nothing that the skilled professionals at plutonic cannot
                    do. The 3D animations or multimedia..
                  </p>
                </blockquote>
              </div>
            </Col>

            <Col>
              <div className="item about-card card-animation">
                <img
                  className="about-card-image"
                  src="./images/about/design-tools.svg"
                  alt=""
                />
                <h3 className="about-card-heading">GRAPHIC DESIGNING</h3>

                <blockquote>
                  <p className="about-card-paragraph">
                    Designers of AW Digital Support show visuals’ power by
                    creating extraordinary designs that represent your business
                    to the world.
                  </p>
                </blockquote>
              </div>
            </Col>
            <Col>
              <div className="item about-card card-animation">
                <img
                  className="about-card-image"
                  src="./images/about/video-tutorials.svg"
                  alt=""
                />
                <h3 className="about-card-heading">VIDEO PROMOTION</h3>

                <blockquote>
                  <p className="about-card-paragraph">
                    Creative team of AW designs engaging videos to serve your
                    target audience. Video is easily accessible & makes a better
                    impression of your products/services.
                  </p>
                </blockquote>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default GraphicDesign;
