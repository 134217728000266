/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";

function EngagingNewAudiences() {
  return (
    <>
      <div className="data-container whiteCard" wrap="true">
        <div className="data-item engaging-image">
          <img
            className="engaging-image"
            src="./images/home/engaging.png"
            alt=""
          />
        </div>
        <div className="data-item defult-padding">
          <div className="image3d"></div>
          <h4 className="heading">
            Engaging New Audiences
            <br />
            Through Smart Approach
          </h4>
          <div className="divider"></div>
          <p className="paragarph">
            In today's dynamic landscape, reaching and captivating new audiences
            is a multifaceted challenge. Our approach is founded on strategic
            ingenuity, leveraging data-driven insights, and harnessing the power
            of cutting-edge technology.
          </p>
          <p className="paragarph">
            We craft tailored solutions that resonate with diverse audiences,
            creating meaningful connections and fostering brand loyalty. With a
            smart and data-backed strategy, we drive engagement, spark
            conversations, and lead the way in reaching new horizons of audience
            engagement.
          </p>
          <NavLink exact to="/aw-contact-us">
            <Button bg="primary">Discover More</Button>
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default EngagingNewAudiences;
