/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import { InputNumber, Form, Input, Col } from "antd";
import { Helmet } from "react-helmet";
import NotFoundSection from "../widgets/NotFoundSection";
import { getAPI, postAPI } from "../../utils/common";
import {
  ALL_CARRER_POSTS,
  CREATE_CARRER_APPLIEDS,
} from "../../utils/AppConstant";
import { displayMessage } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../utils/messages";

function CareerItemModal({ item, show, handleClose }) {
  const [form] = Form.useForm(); // Create a new form instance
  const [selectedFile, setSelectedFile] = useState(null);

  form.setFieldsValue({ "user.apply_for": item["title"] });
  const handleFileChange = (e) => {
    const files = e?.target?.files;
    let filePath = files[0];
    setSelectedFile({ selectedFile: filePath });
    form.setFieldsValue({ "user.resume": filePath });
  };

  const onFinish = (values) => {
    const { name, current_previous_job_title, email, phone, linkedIn_account } =
      values.user;

    const data = new FormData();

    data.append("fullName", name);
    data.append("previousJobTitle", current_previous_job_title);
    data.append("email", email);
    data.append("phone", phone);
    data.append("linkedin_link", linkedIn_account);
    data.append("applied_for", item["title"]);
    data.append("resume", selectedFile.selectedFile);
    const successFn = function (data) {
      if (data["status"] === true) {
        handleClose();
        displayMessage(
          SUCCESS_MESSAGE,
          "Your job application has been successfully submitted."
        );
      } else {
        displayMessage(ERROR_MSG_TYPE, data["message"]);
      }
    };

    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to send the request");
    };

    postAPI(CREATE_CARRER_APPLIEDS, data, successFn, errorFn);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{item["title"]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form layout="vertical" name="nest-messages" onFinish={onFinish}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Row justify="space-between">
              <Col span={12}>
                <Form.Item
                  name={["user", "name"]}
                  label="Full Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name={["user", "current_previous_job_title"]}
                  label="Current/Previous Job Title"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Current/Previous Job Title!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Row justify="space-between">
              <Col span={12}>
                <Form.Item
                  name={["user", "email"]}
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name={["user", "phone"]}
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <InputNumber maxLength={10} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Row justify="space-between">
              <Col span={12}>
                <Form.Item
                  name={["user", "apply_for"]}
                  defaultValue={item["title"]}
                  label="Apply For"
                >
                  <Input
                    defaultValue={item["title"]}
                    disabled={true}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={["user", "linkedIn_account"]}
                  label="LinkedIn Account"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name={["user", "resume"]}
            label="Resume/CV * (PDF, Word, TXT, 6MB Maximum)"
            rules={[
              {
                required: true,
                message: "Please select Resume/CV!",
              },
            ]}
          >
            <Input
              type="file"
              accept=".pdf,.doc,.docx"
              placeholder="Resume/CV * (PDF, Word, 6MB Maximum)"
              beforeUpload={(file) => {
                const isSizeValid = file.size / 1024 / 1024 <= 2; // 2MB maximum size

                if (!isSizeValid) {
                  alert("File size must be 6MB or less.");
                  return false; // Cancel the upload
                }

                return true; // Proceed with the upload
              }}
              onChange={handleFileChange}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

function Career() {
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);

  useLayoutEffect(() => {
    if (state.length === 0) {
      getData();
    }
  }, [state, loading]);
  const getData = () => {
    const successFn = function (data) {
      console.log(data);
      setloading(false);
      setstate(data["data"]);
      console.log(state);
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(ALL_CARRER_POSTS, successFn, errorFn, {});
  };

  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleClose = () => {
    setShow(false);
    setSelectedItem(null);
  };

  const handleShow = (item) => {
    setShow(true);
    setSelectedItem(item);
  };

  return (
    <>
      <div className="contact-page-head">
        <h1 className="team-heading-h1">CAREER @ AW DIGITAL SUPPORT</h1>
        <h4 className="conatct-heading">Join Our Team</h4>
        <p className="conatct-paragarph">
          Join Our Team to explore the Opportunities to grow together with AW
          Digital Support.
        </p>
      </div>
      <div className="greyCard defult-padding" wrap="true">
        <Helmet>
          <title>CAREER</title>
          <meta
            name="description"
            content="CAREER at AW Digital Support offers exciting opportunities to thrive in the dynamic world of digital innovation. Join our dedicated team of experts in mobile app development, web solutions, and technology services. We foster creativity, continuous learning, and professional growth, making us the ideal place to shape your career in the digital landscape."
          />
          <meta
            name="keywords"
            content="AW Digital Support careers, mobile app development jobs, web solutions positions, digital innovation, technology careers, professional growth, digital career opportunities, app development experts, tech jobs, career growth in digital, AW Digital Support, AW, Digital, Digital Support"
          />
          {/* Dynamic OG Tags for Social Sharing */}
          <meta property="og:title" content="CAREER" />
          <meta
            property="og:description"
            content="CAREER at AW Digital Support offers exciting opportunities to thrive in the dynamic world of digital innovation. Join our dedicated team of experts in mobile app development, web solutions, and technology services. We foster creativity, continuous learning, and professional growth, making us the ideal place to shape your career in the digital landscape."
          />
          <meta
            property="og:keywords"
            content="AW Digital Support careers, mobile app development jobs, web solutions positions, digital innovation, technology careers, professional growth, digital career opportunities, app development experts, tech jobs, career growth in digital, AW Digital Support, AW, Digital, Digital Support"
          />
        </Helmet>
        {state.length !== 0 ? (
          <Row loading={loading} xs={1} md={1} className="g-4">
            {state.map((item, idx) => (
              <Col key={idx}>
                <Card>
                  <Card.Body>
                    <div className=" whiteCard" wrap="true">
                      <div className="career-item">
                        <center>
                          <div
                            style={{ float: "left" }}
                            className="career-container whiteCard"
                            wrap="true"
                          >
                            <div className="">
                              <div className="career-card">
                                <i className="fa fa-suitcase career-icon"></i>
                              </div>
                            </div>
                            <div className="">
                              <h4 className="career-title">{item.title}</h4>
                            </div>
                          </div>
                        </center>
                      </div>
                      <div className="career-item" style={{ float: "right" }}>
                        <div className="image3d"></div>
                        <div
                          style={{ float: "right" }}
                          className="career-container whiteCard"
                          wrap="true"
                        >
                          <div className="">
                            <h4 className="career-price">Rs. {item.salary}</h4>
                          </div>
                          <div className="">
                            <h4 className="career-paragarph">
                              <b> &nbsp;-/ {item.sallery_type}</b>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="whiteCard" wrap="true">
                      <div className="career-item">
                        <center>
                          <div
                            style={{ float: "left" }}
                            className="career-container whiteCard"
                            wrap="true"
                          >
                            <div className="">
                              <div className="career-card">{item.job_type}</div>
                            </div>
                            <div className="">
                              <div className="career-card">
                                {item.job_location}
                              </div>
                            </div>
                          </div>
                        </center>
                      </div>
                      <div className="career-item" style={{ float: "right" }}>
                        <div
                          style={{ float: "right" }}
                          className="career-container whiteCard"
                          wrap="true"
                        >
                          <div className="">
                            <Button
                              className="career-btn"
                              onClick={() => handleShow(item)}
                            >
                              Apply Now
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <NotFoundSection title={"No Opening Found"} />
        )}
      </div>

      {selectedItem && (
        <CareerItemModal
          item={selectedItem}
          show={show}
          handleClose={handleClose}
        />
      )}
    </>
  );
}

export default Career;
