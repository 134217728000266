/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { CALL_NUM, MAIL, WHATSAPP_NUM } from "../../utils/AppConstant";
import { Button, InputNumber, Form, Input, Select, Col, Row } from "antd";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { postAPI } from "../../utils/common";
import { CREATE_CONTACTS } from "../../utils/AppConstant";
import { displayMessage } from "../../utils/common";
import { ERROR_MSG_TYPE, SUCCESS_MESSAGE } from "../../utils/messages";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
    phone: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

function ContactUs() {
  let history = useNavigate();
  const onFinish = (values) => {
    const { name, email, phone, whatsappphone, subject, intrestedIn, message } =
      values.user;

    const data = new FormData();

    data.append("name", name);
    data.append("email", email);
    data.append("phone", phone);
    data.append("whatsapp_number", whatsappphone);
    data.append("subject", subject);
    data.append("interested_in", intrestedIn);
    data.append("message", message);
    const successFn = function (data) {
      console.log(data);
      if (data["status"] === true) {
        history("/");
        displayMessage(SUCCESS_MESSAGE, "Request has been submited");
      } else {
        displayMessage(ERROR_MSG_TYPE, data["message"]);
      }
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to send the request");
    };
    postAPI(CREATE_CONTACTS, data, successFn, errorFn);
  };
  return (
    <>
      <div className="contact-page-head">
        <h1 className="team-heading-h1">CONTACT US</h1>
        <h4 className="conatct-heading">
          We are a creative digital <br />
          agency based in India.
        </h4>
        <p className="conatct-paragarph">
          Please fill in the form below or mail us your requirements.
        </p>
      </div>
      <div
        className="conatct-us-container whiteCard defult-padding"
        wrap="true"
      >
        <Helmet>
          <title>CONTACT US</title>
          <meta
            name="description"
            content="Contact AW Digital Support for expert assistance in app development, web design, and digital solutions. Our experienced team is dedicated to bringing your digital ideas to life, providing top-notch services tailored to your unique needs. From concept to launch, we'll guide you through every step of the development process. Reach out to us today and turn your vision into reality with AW Digital Support."
          />
          <meta
            name="keywords"
            content="App development,Web design,Digital solutions,Contact us,AW Digital Support,Expert assistance,Development process,Digital ideas,Top-notch services,Tailored solutions,Concept to launch,Vision into reality, AW Digital Support, AW Digital Support, AW, Digital, Digital Support"
          />
          {/* Dynamic OG Tags for Social Sharing */}
          <meta property="og:title" content="CONTACT US" />
          <meta
            property="og:description"
            content="Contact AW Digital Support for expert assistance in app development, web design, and digital solutions. Our experienced team is dedicated to bringing your digital ideas to life, providing top-notch services tailored to your unique needs. From concept to launch, we'll guide you through every step of the development process. Reach out to us today and turn your vision into reality with AW Digital Support."
          />
          <meta
            property="og:keywords"
            content="App development,Web design,Digital solutions,Contact us,AW Digital Support,Expert assistance,Development process,Digital ideas,Top-notch services,Tailored solutions,Concept to launch,Vision into reality, AW Digital Support, AW Digital Support, AW, Digital, Digital Support"
          />
        </Helmet>
        <div className="contact-item-30 midle-image">
          <center>
            <a href={"https://wa.me/" + WHATSAPP_NUM} target="_blanck">
              <div className="contact-card">
                <center>
                  <i className="fa fa-whatsapp contact-info-icon"></i>
                  <div className="cta-text">
                    <h4 className="contact-info-heading">Chat us</h4>
                    <span>{WHATSAPP_NUM}</span>
                  </div>
                </center>
              </div>
            </a>
            <a href={"tel:" + CALL_NUM} target="_blanck">
              <div className="contact-card">
                <center>
                  <i className="fa fa-phone contact-info-icon"></i>
                  <div className="cta-text">
                    <h4 className="contact-info-heading">Call us</h4>
                    <span>{CALL_NUM}</span>
                  </div>
                </center>
              </div>
            </a>
            <a href={"mailto:" + MAIL} target="_blanck">
              <div className="contact-card">
                {/* <center> */}
                <i className="fa fa-envelope contact-info-icon"></i>
                <div className="cta-text">
                  <h4 className="contact-info-heading">Mail us</h4>
                  <span>{MAIL}</span>
                </div>
                {/* </center> */}
              </div>
            </a>
          </center>
        </div>
        <div className="contact-item-80 ">
          <div className="image3d"></div>
          <h4 className="heading">Contact Us</h4>
          <div className="divider"></div>
          <div className="contact-form-card">
            <Form
              {...layout}
              layout="vertical"
              name="nest-messages"
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <Form.Item style={{ marginBottom: 0 }}>
                <Row style={{ margin: 0, padding: 0 }} justify="space-between">
                  <Col span={11}>
                    <Form.Item
                      name={["user", "name"]}
                      label="Name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={11}>
                    <Form.Item
                      name={["user", "email"]}
                      label="Email"
                      rules={[
                        {
                          type: "email",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}>
                <Row justify="space-between">
                  <Col span={11}>
                    <Form.Item
                      name={["user", "phone"]}
                      label="Phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number!",
                        },
                      ]}
                    >
                      <InputNumber maxLength={10} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>

                  <Col span={11}>
                    <Form.Item
                      name={["user", "whatsappphone"]}
                      label="Whatsapp Number"
                      rules={[
                        {
                          required: true,
                          message: "Please input your whatsapp number!",
                        },
                      ]}
                    >
                      <InputNumber maxLength={10} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}>
                <Row justify="space-between">
                  <Col span={11}>
                    <Form.Item name={["user", "subject"]} label="Subject">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={11}>
                    <Form.Item
                      name={["user", "intrested-in"]}
                      label="Intrested In"
                    >
                      <Select>
                        <Select.Option value="Web Design">
                          Web Design
                        </Select.Option>
                        <Select.Option value="Graphic Design">
                          Graphic Design
                        </Select.Option>
                        <Select.Option value="App Development">
                          App Development
                        </Select.Option>
                        <Select.Option value="Digital Marketing">
                          Digital Marketing
                        </Select.Option>
                        <Select.Option value="Business Development">
                          Business Development
                        </Select.Option>
                        <Select.Option value="Other...">Other...</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item name={["user", "message"]} label="Message">
                <Input.TextArea />
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
