/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from "react";
import { Layout, Spin } from "antd";
import OurWorkSection from "../widgets/OurWorksSection";
import ClientTestimonialSection from "../widgets/ClientTestimonialSection";
import OurClientSection from "../widgets/OurClientSection";
import EngagingNewAudiences from "./widgets/EngagingNewAudiences";
import SliderSection from "./widgets/SliderSection";
import ProductDevelopmentSection from "./widgets/ProductDevelopmentSection";
import DigitalMarketingSection from "./widgets/DigitalMarketingSection";
import GraphicDesignSection from "./widgets/GraphicDesignSection";
import OurMoscotSection from "./widgets/OurMoscotSection";
import { Modal } from "antd";
import { getAPI } from "../../utils/common";
import { ALL_POPUP, IMAGE_POPUP_URL } from "../../utils/AppConstant";
import { displayMessage } from "../../utils/common";
import { ERROR_MSG_TYPE } from "../../utils/messages";
const { Content } = Layout;
function HomeScreen() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(false);

  useLayoutEffect(() => {
    getData();
  }, []);
  const getData = () => {
    setloading(true);
    const successFn = function (data) {
      if (data["status"] === true) {
        setstate(data["data"]);
        setIsModalOpen(true);
        setloading(false);
      } else {
        setloading(false);
      }
    };
    const errorFn = function () {
      setloading(false);
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(ALL_POPUP, successFn, errorFn, {});
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Content className="site-layout">
        <SliderSection />
        <OurClientSection />
        <EngagingNewAudiences />
        <ProductDevelopmentSection />
        <DigitalMarketingSection />
        <GraphicDesignSection />
        <OurWorkSection />
        <OurMoscotSection />
        <ClientTestimonialSection />
      </Content>

      {state.length > 0 ? (
        <Modal
          open={isModalOpen}
          loading={loading}
          footer={null}
          onOk={handleOk}
          onCancel={handleCancel}
          className="ImageModal__Content"
          overlayClassName="ImageModal__Overlay"
        >
          <Spin spinning={loading}>
            <a href={state[0]["link"]} target="_blanck">
              <img
                className="ImageModal__Image"
                src={IMAGE_POPUP_URL + state[0].image}
              />
            </a>
          </Spin>
        </Modal>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default HomeScreen;
