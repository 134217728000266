/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Card from "react-bootstrap/Card";
import { Image } from "antd";
import { Link } from "react-router-dom";

import { getAPI } from "../../utils/common";
import { ALL_DYNAMIC_CONTENT, BLOG_BASE_URL } from "../../utils/AppConstant";
import { displayMessage } from "../../utils/common";
import { ERROR_MSG_TYPE } from "../../utils/messages";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
//Owl Carousel Settings
const options = {
  margin: 50,
  responsiveClass: true,
  nav: true,
  autoplay: true,
  navText: [
    '<button class="owl-prev">Previous</button>',
    '<button class="owl-next">Next</button>',
  ],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};

function OurWorkSection() {
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);

  useLayoutEffect(() => {
    getData();
  }, []);
  const getData = () => {
    setloading(true);
    const successFn = function (data) {
      setstate(data["data"]);
      setloading(false);
    };
    const errorFn = function () {
      setloading(false);
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(ALL_DYNAMIC_CONTENT + "1", successFn, errorFn, {});
  };
  return (
    <>
      {state.length > 0 ? (
        <div className="whiteCard-Center defult-padding" wrap="true">
          <center>
            <h4 className="heading">OUR WORK</h4>
            <div className="divider"></div>
          </center>
          <OwlCarousel className="slider-items owl-carousel" {...options}>
            {state.map((item, itemIndex) => (
              <div key={itemIndex} className="item portfolio-card">
                <Card loading={loading}>
                  <Image
                    variant="top"
                    src={BLOG_BASE_URL + item.images[0].image}
                  />
                  {/* <Link to={`/aw-portfolio-detail?slug=${item.slug}`}> */}
                  <Link>
                    <Card.Body>
                      <Card.Title>{item.meta_title}</Card.Title>
                      <Card.Text>{item.meta_description}</Card.Text>
                    </Card.Body>
                  </Link>
                </Card>
              </div>
            ))}
          </OwlCarousel>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default OurWorkSection;
