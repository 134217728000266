/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { getAPI } from "../../utils/common";
import {
  ALL_TESTIMONIAL,
  CLENT_COMPANY_BASE_URL,
} from "../../utils/AppConstant";
import { displayMessage } from "../../utils/common";
import { ERROR_MSG_TYPE } from "../../utils/messages";
import { Skeleton } from "antd";

function OurClientSection() {
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(false);

  useLayoutEffect(() => {
    getData();
  }, []);
  const getData = () => {
    setloading(true);
    const successFn = function (data) {
      setstate(data["data"]);
      setloading(false);
    };
    const errorFn = function () {
      setloading(false);
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(ALL_TESTIMONIAL, successFn, errorFn, {});
  };

  return (
    <>
      {loading === false ? (
        state.length > 0 ? (
          <div className="data-container greyCard " wrap="true">
            <div className="client-item-20 defult-padding-left defult-padding-right small-padding-top small-padding-bottom">
              <h4 className="client-heading">Pleased Patrons</h4>
              {/* <h4 className="client-heading-h1">{state.length}+</h4> */}
              <h4 className="client-heading-h1">180+</h4>
            </div>
            <div className="client-item-80">
              <Marquee loading={loading}>
                {state.map((item, itemIndex) => (
                  <div key={itemIndex} className="client-card">
                    <img
                      loading="lazy"
                      className="client-image"
                      src={CLENT_COMPANY_BASE_URL + item.company_logo}
                      alt=""
                    />
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        ) : (
          <div></div>
        )
      ) : (
        <div>
          <Skeleton.Input active size="large" block />
          <Skeleton.Input active size="large" block />
        </div>
      )}
    </>
  );
}

export default OurClientSection;
