/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from "react";
import SectionWidget from "../widgets/SectionWidget";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import NotFoundSection from "../widgets/NotFoundSection";
import { getAPI } from "../../utils/common";
import { ALL_DYNAMIC_CONTENT, BLOG_BASE_URL } from "../../utils/AppConstant";
import { displayMessage } from "../../utils/common";
import { ERROR_MSG_TYPE } from "../../utils/messages";
function BlogsScreen() {
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);

  useLayoutEffect(() => {
    if (state.length === 0) {
      getData();
    }
  }, [state, loading]);
  const getData = () => {
    const successFn = function (data) {
      console.log(data);
      setloading(false);
      setstate(data["data"]);
      console.log(state);
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(ALL_DYNAMIC_CONTENT + "0", successFn, errorFn, {});
  };
  return (
    <>
      <SectionWidget loading={loading} titleText={"Blogs"} />
      {state.length !== 0 ? (
        <div className="greyCard defult-padding">
          <Helmet>
            <title>LATEST BLOGS</title>
            <meta
              name="description"
              content="Stay informed and inspired with our latest blogs. Explore a diverse range of topics, from technology and lifestyle to education and more. Our up-to-date content offers insights, tips, and knowledge to keep you engaged and informed. Whether you're a tech enthusiast, a lifestyle connoisseur, or simply curious, our latest blogs have something for everyone. Discover the freshest perspectives and stay in the know with our regularly updated blog posts."
            />
            <meta
              name="keywords"
              content="Latest blogs,Technology trends,Lifestyle tips,Health insights,Innovation articles,Expert writers,In-depth knowledge,Valuable insights,Fresh content,Thought-provoking reads, AW Digital Support, AW, Digital, Digital Support"
            />
            {/* Dynamic OG Tags for Social Sharing */}
            <meta property="og:title" content="LATEST BLOGS" />
            <meta
              property="og:description"
              content="Stay informed and inspired with our latest blogs. Explore a diverse range of topics, from technology and lifestyle to education and more. Our up-to-date content offers insights, tips, and knowledge to keep you engaged and informed. Whether you're a tech enthusiast, a lifestyle connoisseur, or simply curious, our latest blogs have something for everyone. Discover the freshest perspectives and stay in the know with our regularly updated blog posts."
            />
            <meta
              property="og:keywords"
              content="Latest blogs,Technology trends,Lifestyle tips,Health insights,Innovation articles,Expert writers,In-depth knowledge,Valuable insights,Fresh content,Thought-provoking reads, AW Digital Support, AW, Digital, Digital Support"
            />
          </Helmet>
          <center>
            <h4 className="heading">
              Latest <strong className="company-name">Blogs</strong>
            </h4>
            <div className="divider"></div>
          </center>
          <Row xs={1} md={3} className="g-4">
            {state.map((item, itemIndex) => (
              <div key={item.id}>
                <Col key={itemIndex}>
                  <Card>
                    <Card.Img
                      variant="top"
                      src={BLOG_BASE_URL + item.images[0].image}
                    />
                    <Link to={`/blog-detail?slug=${item.slug}`}>
                      <Card.Body>
                        <Card.Title>{item.meta_title}</Card.Title>
                        <Card.Text>{item.meta_description}</Card.Text>
                      </Card.Body>
                    </Link>
                  </Card>
                </Col>
              </div>
            ))}
          </Row>
        </div>
      ) : (
        <NotFoundSection title={"No Work Found"} />
      )}
    </>
  );
}

export default BlogsScreen;
