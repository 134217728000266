/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SectionWidget from "../widgets/SectionWidget";
import { Helmet } from "react-helmet";
import OurFeatureSection from "../widgets/OurFeaturesSection";
function ProductDevelopment() {
  return (
    <>
      <SectionWidget titleText={"Product Development"} />
      <div className="whiteCard-Center defult-padding" wrap="true">
        <Helmet>
          <title>DEVELOPMENT</title>
          <meta
            name="description"
            content="AW Digital Support is a leading development company specializing in creating innovative and scalable digital solutions. With a team of experienced developers, we offer end-to-end services for web and mobile app development. Our focus on quality, agility, and client satisfaction ensures your project's success. From concept to deployment, we provide customized solutions to meet your unique business needs. Collaborate with us to transform your ideas into powerful, user-friendly applications."
          />
          <meta
            name="keywords"
            content="Development, web, mobile, innovative, scalable, experienced, end-to-end, quality-focused, agile, client satisfaction, customized solutions, user-friendly applications, AW Digital Support, AW Digital Support, AW, Digital, Digital Support"
          />
          {/* Dynamic OG Tags for Social Sharing */}
          <meta property="og:title" content="DEVELOPMENT" />
          <meta
            property="og:description"
            content="AW Digital Support is a leading development company specializing in creating innovative and scalable digital solutions. With a team of experienced developers, we offer end-to-end services for web and mobile app development. Our focus on quality, agility, and client satisfaction ensures your project's success. From concept to deployment, we provide customized solutions to meet your unique business needs. Collaborate with us to transform your ideas into powerful, user-friendly applications."
          />
          <meta
            property="og:keywords"
            content="Development, web, mobile, innovative, scalable, experienced, end-to-end, quality-focused, agile, client satisfaction, customized solutions, user-friendly applications, AW Digital Support, AW Digital Support, AW, Digital, Digital Support"
          />
        </Helmet>
        <center>
          <h4 className="heading">
            CREATION <strong className="company-name">PROCCESS</strong>
          </h4>
          <div className="divider"></div>
          <img
            className="home-slider-img image-animation-right-in"
            src="./images/development/development.png"
            alt=""
          />
        </center>
      </div>
      <div className="data-container greyCard defult-padding" wrap="true">
        <div className="">
          <div className="image3d"></div>
          <h1 className="team-heading-h1">Development?</h1>
          <h3 className="team-heading heading-animation">
            PRODUCT <strong className="company-name">DEVELOPMENT</strong>
          </h3>
          <div className="divider"></div>
          <p className="paragarph">
            Your ideas, your requirements, and create a product/ software that
            helps the growth of your business. Our tech team provides the most
            innovative solutions to all your problems. We have assembled a team
            of the best and brightest minds in software development and
            leadership, giving our clients access to the most cutting-edge
            technology. You can rest assured, you’re in good hands, we have
            years of experience in software development.
          </p>
          <p className="paragarph">
            Our goal is to create something you’re proud of and that helps to
            take your business to next level.
          </p>
        </div>
      </div>
      <OurFeatureSection showTitle={false} />
    </>
  );
}

export default ProductDevelopment;
