/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SectionWidget from "../widgets/SectionWidget";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function DigitalMarketing() {
  return (
    <>
      <SectionWidget titleText={"Digital Marketing"} />
      <div className="data-container whiteCard defult-padding" wrap="true">
        <Helmet>
          <title>DIGITAL MARKETING</title>
          <meta
            name="description"
            content="AW Digital Support offers cutting-edge digital marketing services to propel your online presence and drive success in the digital landscape. We specialize in SEO, PPC, social media, and content marketing, tailoring strategies to your unique goals. Our experienced team harnesses the power of data-driven insights to optimize campaigns, enhance visibility, and boost conversions. We're your dedicated partner in achieving digital marketing excellence."
          />
          <meta
            name="keywords"
            content="digital marketing, SEO, PPC, social media, and content strategies, optimizing campaigns for enhanced visibility and conversions, AW Digital Support, AW, Digital, Digital Support"
          />
          {/* Dynamic OG Tags for Social Sharing */}
          <meta property="og:title" content="DIGITAL MARKETING" />
          <meta
            property="og:description"
            content="AW Digital Support offers cutting-edge digital marketing services to propel your online presence and drive success in the digital landscape. We specialize in SEO, PPC, social media, and content marketing, tailoring strategies to your unique goals. Our experienced team harnesses the power of data-driven insights to optimize campaigns, enhance visibility, and boost conversions. We're your dedicated partner in achieving digital marketing excellence."
          />
          <meta
            property="og:keywords"
            content="digital marketing, SEO, PPC, social media, and content strategies, optimizing campaigns for enhanced visibility and conversions, AW Digital Support, AW, Digital, Digital Support"
          />
        </Helmet>
        <div className="data-item ">
          <h4 className="company-heading heading-animation">ABOUT US</h4>
          <div className="divider"></div>
          <p className="paragarph">
            Words of our writers and creators are mightier than weapons. They
            not only create the scripts and prepare the contents but also plan
            the campaigns and make the brands trend online. From socializing to
            advertising, they do it all in their buzzing style. Branding for
            them is as easy as breathing, yes, effortless.
          </p>
          <p className="paragarph">
            From Social Media Marketing to Affiliate marketing, email to
            pay-per-click advertising, We can do it all. befriending on Fb,
            following on Insta, Subscribing on Youtube.
          </p>
        </div>
        <div className="data-item engaging-image">
          <center>
            <img
              className="engaging-image image-animation-left-in"
              src="./images/digital_marketing/digital_marketing_abouts.png"
              alt=""
            />
          </center>
        </div>
      </div>
      <div className="data-container greyCard defult-padding" wrap="true">
        <div className="">
          <div className="image3d"></div>
          <h3 className="team-heading heading-animation">
            <strong className="company-name">DIGITAL MARKETING</strong>
          </h3>
          <div className="divider"></div>
          <h1
            className="team-heading-h1"
            style={{ color: "rgb(87, 100, 124)", fontStyle: "italic" }}
          >
            We are creating new trends!
          </h1>
          <p className="paragarph">
            The words of our writers are mightier than weapons. They not only
            create the scripts and prepare the contents but also plan the
            campaigns and make the brands trend online. From socializing to
            advertising, they do it all in their buzzing style. Branding for
            them is as easy as breathing, yes, effortless.
          </p>
          <p className="paragarph">
            From Social Media Marketing to Affiliate marketing, email to pay per
            click advertising, We can do it all. Tweeting on Twitter,
            befriending on Fb, following on Insta, Subscribing on Youtube, we
            won’t let you have FOMO. We’ll be there for you!
          </p>
        </div>
      </div>
      <div className="whiteCard-Center defult-padding" wrap="true">
        <Container>
          <Row>
            <Col>
              <div className="item digital-m-card card-animation">
                <img
                  className="about-card-image"
                  src="./images/digital_marketing/like.svg"
                  alt=""
                />
                <h3 className="about-card-heading">SOCIAL MEDIA MARKETING</h3>

                <blockquote>
                  <p className="about-card-paragraph">
                    Any kind of information reaches youth faster on social media
                    than any other way. Marketing on social media is much more
                    effective than any other form, and Plutonic knows how to
                    utilize social media in the most beneficial way and make you
                    trend on all the social circles
                  </p>
                </blockquote>
              </div>
            </Col>

            <Col>
              <div className="item digital-m-card card-animation">
                <img
                  className="about-card-image"
                  src="./images/digital_marketing/content-paste.svg"
                  alt=""
                />
                <h3 className="about-card-heading">CONTENT MARKETING</h3>

                <blockquote>
                  <p className="about-card-paragraph">
                    Designers of AW Digital Support show visuals’ power by
                    creating extraordinary designs that represent your business
                    to the world.
                  </p>
                </blockquote>
              </div>
            </Col>
            <Col>
              <div className="item digital-m-card card-animation">
                <img
                  className="about-card-image"
                  src="./images/digital_marketing/pie-chart.svg"
                  alt=""
                />
                <h3 className="about-card-heading">
                  SEARCH ENGINE OPTIMIZATION
                </h3>

                <blockquote>
                  <p className="about-card-paragraph">
                    Search Engine Optimization requires technical as well as
                    writing skills, and the professionals at Plutonic are well
                    trained in both aspects. When building a new website, or
                    even maintaining an old one, Search Engine Optimization is
                    necessary to stay on top of the rankings.
                  </p>
                </blockquote>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default DigitalMarketing;
