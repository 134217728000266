import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { WHATSAPP_NUM } from "../../utils/AppConstant";

const HeaderWidget = () => {
  const location = useLocation();
  // console.log(location.pathname);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar
        key="xl"
        expand="xl"
        // bg="light"
        // data-bs-theme="light"
        fixed="top"
      >
        <Container fluid className="aw-nav">
          <Navbar.Brand>
            <NavLink exact to="/">
              <img className="logo" src="./images/aw_logo.png" alt="" />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-xl`}
            aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                <img className="logo" src="./images/aw_logo.png" alt="" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {location.pathname !== "/" ? (
                  <NavLink className="navMenu" to="/" activeClassName="active">
                    Home
                  </NavLink>
                ) : (
                  <div></div>
                )}

                {/* <NavLink
                  className="navMenu"
                  exact
                  to="/about"
                  activeClassName="active"
                >
                  About-Us
                </NavLink> */}

                <NavLink
                  className="navMenu"
                  exact="true"
                  to="/graphic-design"
                  activeClassName="active"
                >
                  Graphic Design
                </NavLink>

                <NavLink
                  className="navMenu"
                  exact="true"
                  to="/digital-marketing"
                  activeClassName="active"
                >
                  Digital Marketing
                </NavLink>

                <NavLink
                  className="navMenu"
                  exact="true"
                  to="/product-development"
                  activeClassName="active"
                >
                  Product Development
                </NavLink>

                <NavLink
                  className="navMenu"
                  exact="true"
                  to="/aw-portfolio"
                  activeClassName="active"
                >
                  Portfolio
                </NavLink>
                <NavLink
                  className="navMenu"
                  exact="true"
                  to="/aw-contact-us"
                  activeClassName="active"
                >
                  Contact-Us
                </NavLink>
                {/* <NavDropdown
                  className="navMenuDrop"
                  title="Our Services"
                  id={`offcanvasNavbarDropdown-expand-xl`}
                >
                  <NavDropdown.Item className="btn-primary">
                    <NavLink
                      className="navMenu"
                      exact
                      to="/graphic-design"
                      activeClassName="active"
                    >
                      Graphic Design
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="btn-primary">
                    <NavLink
                      className="navMenu"
                      exact
                      to="/digital-marketing"
                      activeClassName="active"
                    >
                      Digital Marketing
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="btn-primary">
                    <NavLink
                      className="navMenu"
                      exact
                      to="/product-development"
                      activeClassName="active"
                    >
                      Product Development
                    </NavLink>
                  </NavDropdown.Item>
                </NavDropdown> */}
              </Nav>
              <a
                href={"https://wa.me/" + WHATSAPP_NUM}
                target="_blanck"
                className="nav-whatsapp-btn"
              >
                <i className="fa fa-whatsapp  text-white"></i> Whatsapp
              </a>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default HeaderWidget;
