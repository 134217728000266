/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import SectionWidget from "../widgets/SectionWidget";
import OurFeatureSection from "../widgets/OurFeaturesSection";
function AboutScreen() {
  return (
    <>
      <SectionWidget titleText={"AboutUs"} />
      <div className="data-container whiteCard" wrap="true">
        <Helmet>
          <title>ABOUT US</title>
          <meta
            name="description"
            content="AW Digital Support is a leading app development company, specializing in creating innovative and high-quality mobile applications. With a team of skilled developers, we provide tailored solutions in both Flutter and React Native. Our customer-centric approach ensures your unique app vision is transformed into a functional reality. From concept to execution, we deliver top-notch results, consistently exceeding expectations. Our keywords include mobile app development, Flutter, React Native, custom solutions, and client-focused services. Choose AW Digital Support for your app development needs, and experience excellence in every aspect of your project."
          />
          <meta
            name="keywords"
            content="Mobile App Development, Flutter, React Native, Custom Solutions, Client-Centric, Innovative Solutions, AW Digital Support, AW, Digital, Digital Support"
          />
          {/* Dynamic OG Tags for Social Sharing */}
          <meta property="og:title" content="ABOUT US" />
          <meta
            property="og:description"
            content="AW Digital Support is a leading app development company, specializing in creating innovative and high-quality mobile applications. With a team of skilled developers, we provide tailored solutions in both Flutter and React Native. Our customer-centric approach ensures your unique app vision is transformed into a functional reality. From concept to execution, we deliver top-notch results, consistently exceeding expectations. Our keywords include mobile app development, Flutter, React Native, custom solutions, and client-focused services. Choose AW Digital Support for your app development needs, and experience excellence in every aspect of your project."
          />
          <meta
            property="og:keywords"
            content="Mobile App Development, Flutter, React Native, Custom Solutions, Client-Centric, Innovative Solutions, AW Digital Support, AW, Digital, Digital Support"
          />
        </Helmet>
        <div className="data-item defult-padding">
          <div className="image3d"></div>
          <h4 className="company-heading heading-animation">
            ABOUT <strong className="company-name">AW DIGITAL SUPPORT</strong>
          </h4>
          <div className="divider"></div>
          <p className="paragarph">
            We are a team of top talent delivering AW Digital Support is founded
            by a group of youth enthusiasts to change the working style of
            different businesses by shifting their work to the digital world. We
            help business owners understand the game of digital presence by
            providing them full technical support Say- Web designing, Web
            Development, Digital Marketing, Social Media Marketing, SEO, Graphic
            Designing. We love creating campaigns that bring in results. That’s
            why we exist. We help our clients with everything from paid
            campaigns to design content to attract organic traffic.
          </p>
          <Button bg="primary">Discover More</Button>
        </div>
        <div className="data-item engaging-image">
          <img
            className="engaging-image image-animation-right-in"
            src="./images/home/engaging.png"
            alt=""
          />
        </div>
      </div>
      <div className="data-container greyCard defult-padding" wrap="true">
        <div className="data-item ">
          <h1 className="team-heading-h1">Who We Are?</h1>
          <h3 className="team-heading heading-animation">
            WE ARE <strong className="company-name">DIGITAL</strong> EXPLORERS
          </h3>
        </div>
        <div className="about-item ">
          <div className="image3d"></div>
          <p className="paragarph">
            Creative designers and Expert Professionals follow the trend to keep
            pace with the dynamic world. Exploring the new features and getting
            results is our priority. Our belief is to work in an open and
            collaborative environment to exchange emerging ideas and success.
          </p>
          <hr />
          <div className="row">
            <div className="col-xl-3 col-md-3">
              <center>
                <h2 className="team-heading">
                  <strong className="company-name">172+</strong>
                </h2>
                <b>
                  <span>SATISFIED CLIENTS</span>
                </b>
              </center>
            </div>
            <div className="col-xl-3 col-md-3">
              <center>
                <h2 className="team-heading">
                  <strong className="company-name">242+</strong>
                </h2>
                <b>
                  <span>PROJECTS</span>
                </b>
              </center>
            </div>
            <div className="col-xl-3 col-md-3">
              <center>
                <h2 className="team-heading">
                  <strong className="company-name">19+</strong>
                </h2>
                <b>
                  <span>ACTIVE PROJECT</span>
                </b>
              </center>
            </div>
            <div className="col-xl-3 col-md-3">
              <center>
                <h2 className="team-heading">
                  <strong className="company-name">218+</strong>
                </h2>
                <b>
                  <span>DONE PROJECTS</span>
                </b>
              </center>
            </div>
          </div>
        </div>
      </div>
      <div className="data-container whiteCard" wrap="true">
        <div className="data-item engaging-image">
          <center>
            <img
              className="engaging-image image-animation-left-in"
              src="./images/about/about.jpg"
              alt=""
            />
          </center>
        </div>
        <div className="data-item defult-padding">
          <h4 className="company-heading">
            Our <strong className="company-name">Expertise.</strong>
          </h4>
          <div className="divider"></div>
          <p className="paragarph">
            We are a team of top talent delivering enterprise solutions
            globally. we evolve with the advancement in technology because we
            believe in making our technology as your innovation.
          </p>
          <p className="paragarph">
            Our experts provide a wide range of services including app design,
            web development, digital marketing, ecommerce solutions and cloud
            development. We stay updated with the technology to build innovative
            digital products that meet client requirements across multiple
            business verticals and domains by housing some of the best
            professionals in the industry.
          </p>
        </div>
      </div>
      <OurFeatureSection showTitle={true} />
    </>
  );
}

export default AboutScreen;
