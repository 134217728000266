/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { getAPI } from "../../utils/common";
import { ALL_TESTIMONIAL } from "../../utils/AppConstant";
import { displayMessage } from "../../utils/common";
import { ERROR_MSG_TYPE } from "../../utils/messages";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
//Owl Carousel Settings
const options = {
  margin: 50,
  responsiveClass: true,
  nav: true,
  autoplay: true,
  navText: [
    '<button class="owl-prev">Previous</button>',
    '<button class="owl-next">Next</button>',
  ],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};
function ClientTestimonialSection() {
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);

  useLayoutEffect(() => {
    if (state.length === 0) {
      getData();
    }
  }, [state, loading]);
  const getData = () => {
    const successFn = function (data) {
      const filteredData = data["data"].filter(
        (item) => item.name !== "Unknown"
      );

      console.log(filteredData);
      setloading(false);
      setstate(filteredData);
      console.log(state);
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(ALL_TESTIMONIAL, successFn, errorFn, {});
  };
  return (
    <>
      {state.length > 0 ? (
        <div className="whiteCard-Center defult-padding" wrap="true">
          <center>
            <h4 className="heading">CLIENT TESTIMONIALS</h4>
            <div className="divider"></div>
          </center>
          <OwlCarousel className="slider-items owl-carousel" {...options}>
            {state.map((item, itemIndex) => (
              <div className="item">
                <figure className="snip1533">
                  <figcaption>
                    <blockquote>
                      <p>{item.description}</p>
                    </blockquote>
                    <h3>{item.name}</h3>
                    <h4>{item.company_name}</h4>
                  </figcaption>
                </figure>
              </div>
            ))}
          </OwlCarousel>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default ClientTestimonialSection;
