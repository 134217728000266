/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from "react";
import { getAPI } from "../../../utils/common";
import { ALL_SLIDERBANNER, IMAGE_SLIDER_URL } from "../../../utils/AppConstant";
import { displayMessage } from "../../../utils/common";
import { ERROR_MSG_TYPE } from "../../../utils/messages";
import Carousel from "react-bootstrap/Carousel";
import { Skeleton } from "antd";

function SliderSection() {
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);

  useLayoutEffect(() => {
    getData();
  }, []);
  const getData = () => {
    setloading(true);
    const successFn = function (data) {
      // console.log(data);
      if (data.status === true) {
        setstate(data["data"]);
        setloading(false);
      } else {
        setloading(false);
        displayMessage(ERROR_MSG_TYPE, data.message);
      }
      // console.log(state);
    };
    const errorFn = function () {
      setloading(false);
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(ALL_SLIDERBANNER, successFn, errorFn, {});
  };

  return (
    <>
      {loading === false ? (
        state.length > 0 ? (
          <Carousel className="home-slider" autoplay>
            {state.map((item, itemIndex) => (
              <Carousel.Item key={itemIndex} interval={1000}>
                <div
                  style={{
                    backgroundImage: `url(${IMAGE_SLIDER_URL}${item.image})`,
                  }}
                  className="background background-filter"
                >
                  <img
                    className="home-slider-img"
                    src={IMAGE_SLIDER_URL + item.image}
                    alt="First slide"
                    style={{
                      position: "relative",
                      zIndex: 1,
                    }}
                  />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <div></div>
        )
      ) : (
        <div>
          <Skeleton active size="large" block />
          <Skeleton active size="large" block />
          <Skeleton active size="large" block />
          <Skeleton active size="large" block />
        </div>
      )}
    </>
  );
}

export default SliderSection;
