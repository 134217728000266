/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from "react";
import SectionWidget from "../widgets/SectionWidget";
import Card from "react-bootstrap/Card";
import { Image } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import NotFoundSection from "../widgets/NotFoundSection";
import { getAPI } from "../../utils/common";
import {
  DYNAMIC_CONTENT_CATEGORY_WISE,
  BLOG_BASE_URL,
} from "../../utils/AppConstant";
import { displayMessage } from "../../utils/common";
import { ERROR_MSG_TYPE } from "../../utils/messages";
function PortfolioScreen() {
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);

  useLayoutEffect(() => {
    if (state.length === 0) {
      getData();
    }
  }, [state, loading]);
  const getData = () => {
    const successFn = function (data) {
      console.log(data);
      setloading(false);
      setstate(data["data"]);
      console.log(state);
    };
    const errorFn = function () {
      displayMessage(ERROR_MSG_TYPE, "Unable to Get Data");
    };
    getAPI(DYNAMIC_CONTENT_CATEGORY_WISE + "1", successFn, errorFn, {});
  };

  return (
    <>
      <SectionWidget loading={loading} titleText={"Portfolio"} />
      {state.length !== 0 ? (
        <div className="whiteCard defult-padding">
          <Helmet>
            <title>OUR WORKS</title>
            <meta
              name="description"
              content="At AW Digital, our works speak volumes about our commitment to excellence. We take pride in our diverse portfolio of cutting-edge projects, ranging from mobile app development to web design and digital marketing. Our team's dedication and expertise ensure that each project we undertake is a masterpiece of innovation, functionality, and aesthetics. Discover our works and see how we can transform your ideas into reality."
            />
            <meta
              name="keywords"
              content="Mobile app development,Web design,Digital marketing,Portfolio,Innovative projects,Excellence,Dedication,Expertise,Innovation,Functionality,Aesthetics,Transformation,Commitment, AW Digital Support, AW, Digital, Digital Support"
            />
            {/* Dynamic OG Tags for Social Sharing */}
            <meta property="og:title" content="OUR WORKS" />
            <meta
              property="og:description"
              content="At AW Digital, our works speak volumes about our commitment to excellence. We take pride in our diverse portfolio of cutting-edge projects, ranging from mobile app development to web design and digital marketing. Our team's dedication and expertise ensure that each project we undertake is a masterpiece of innovation, functionality, and aesthetics. Discover our works and see how we can transform your ideas into reality."
            />
            <meta
              property="og:keywords"
              content="Mobile app development,Web design,Digital marketing,Portfolio,Innovative projects,Excellence,Dedication,Expertise,Innovation,Functionality,Aesthetics,Transformation,Commitment, AW Digital Support, AW, Digital, Digital Support"
            />
          </Helmet>
          <center>
            <h4 className="heading">
              OUR <strong className="company-name">WORK</strong>
            </h4>
            <div className="divider"></div>
          </center>
          <Tabs
            defaultActiveKey="0" // Set the default tab index
            id="uncontrolled-tab-example"
            className="mb-3 "
          >
            {state.map((category, categoryIndex) => (
              <Tab
                key={categoryIndex}
                eventKey={categoryIndex.toString()}
                title={category.category.title}
              >
                <section className="portfolio-layout">
                  {category.blogs.map((item, itemIndex) => (
                    <div className="portfolio-card" key={item.id}>
                      <Card>
                        <Image
                          variant="top"
                          style={{ borderRadius: 5 }}
                          src={BLOG_BASE_URL + item.images[0].image}
                        />
                        {/* <Link to={`/aw-portfolio-detail?slug=${item.slug}`}> */}
                        <Link>
                          <Card.Body>
                            <Card.Title>{item.meta_title}</Card.Title>
                            <Card.Text>{item.meta_description}</Card.Text>
                          </Card.Body>
                        </Link>
                      </Card>
                    </div>
                  ))}
                </section>
              </Tab>
            ))}
          </Tabs>
        </div>
      ) : (
        <NotFoundSection title={"No Work Found"} />
      )}
    </>
  );
}

export default PortfolioScreen;
